<template>

  <div class="row">
    <div class="flex md12 xs12">

      <vac-card :title="$t('Insights products')">

        <Actions :export-to-excel="exportToExcel"/>

        <div class="row">
          <div class="flex xs12 md3">
            <h1 class="title-table">Total {{ totalCount }}</h1>
          </div>
        </div>

        <div class="row align--center">

          <div class="flex xs12 md4">
            <va-input
              :value="searchName"
              class="input--border"
              removable
              @input="search">
              <va-icon slot="prepend" name="fa fa-search"/>
            </va-input>
          </div>

          <div class="flex xs12 md3 offset--md1">
            <va-select
              v-model="perPage"
              :label="$t('tables.perPage')"
              :options="perPageOptions"
              noClear
            />
          </div>
        </div>

        <!--        <FiltersByColor :colors="colors"
                  title="status"
                  @callback="callback" />-->

        <vac-data-table
          :data="filteredData"
          :fields="fields"
          :per-page="parseInt(perPage)">

          <template slot="referrerLink" slot-scope="props">
            <a :href="props.rowData.key" class="link-icon" rel="noreferrer" target="_blank">
              <i class="va-icon fa fa-external-link" style="font-size: 26px;"></i>
            </a>
          </template>

        </vac-data-table>

      </vac-card>

    </div>
  </div>

</template>

<script>
import {debounce} from 'lodash';
import HelperExcel from '../../services/Helpers/HelperExcel';
//import FiltersByColor from "../filters/FiltersByColor";
import Actions from "../actions/Actions";

export default {
  name: 'InsightsTable',

  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    //FiltersByColor,
    Actions,
    VacDataTable: () => import('./VacDataTable')
  },

  data() {
    return {
      totalCount: 0,
      perPageOptions: ['100', '200', '500', '1000'],
      perPage: '100',
      searchName: '',
      tableData: this.data,
      searchStatus: '',
      /*colors: [
        {
          "label": "Unknown",
          "color": "#45db54",
          "value": "Unknown",
        },
        {
          "label": "Broken",
          "color": "#f3972b",
          "value": "Broken",
        },
        {
          "label": "Empty",
          "color": "#d6dee2",
          "value": "Empty",
        },
      ],*/
    };
  },
  computed: {
    fields() {
      return [
        {
          name: 'license_name',
          sortField: 'license_name',
          title: this.$t('License name'),
          width: '30%',
          dataClass: "--nowrap --break-word",
        },
        {
          name: 'retailer_name',
          sortField: 'retailer_name',
          title: this.$t('Retailer name'),
          width: '30%',
          dataClass: "--nowrap --break-word",
        },
        {
          name: 'product',
          sortField: 'product',
          title: this.$t('Retailer Product Id'),
          width: '20%',
          dataClass: "--nowrap --break-word",
        },
        {
          name: 'doc_count',
          sortField: 'doc_count',
          title: this.$t('Count'),
          width: '10%',
          dataClass: '--nowrap --break-word',
        },
        {
          name: '__slot:referrerLink',
          title: this.$t('Link'),
          width: '10%',
        },
      ];
    },

    filteredData() {
      let data = this.data;

      if (this.searchName.length) {
        const searchNames = this.searchName.split(',').map(value => value.trim())
        data = data.filter(item => {
          let found = false
          searchNames.forEach(searchName => found = found ||
            (
              item.retailer_name.toLowerCase().includes(searchName.toLowerCase()) ||
              item.product.toLowerCase().includes(searchName.toLowerCase()) ||
              item.license_name.toLowerCase().includes(searchName.toLowerCase()
            )));
          return found;
        });
      }

      /*if (this.searchStatus.length) {
        data = data.filter(info => info.status === this.searchStatus);
      }*/

      this.updateTotalCount(data.length);

      return data;
    },
  },

  methods: {

    updateTotalCount(value) {
      this.totalCount = value;
    },

    exportToExcel() {
      const labels = ["License Name", "Retailer Name", "Retailer Product Id", "Count", "Link"];
      const data = [];
      const columnWidth = [];

      this.data.forEach(info => {

        const {license_name, retailer_name, product, doc_count, key} = info;
        const obj = {
          "License Name": license_name,
          "Retailer Name": retailer_name,
          "Retailer Product Id": product,
          "Count": doc_count,
          "Link": key,
        };
        data.push(obj);
      });

      labels.forEach(() => {
        columnWidth.push(16);
      });

      HelperExcel.exportToExcel({}, 'Insights', labels, data, columnWidth);
    },

    callback(value) {
      this.searchStatus = value;
    },

    search: debounce(function (value) {
      this.searchName = value;
    }, 400),
  },

};
</script>

<style lang="scss">
.btn-status-insight {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 88px;
  height: 52px;
  border-radius: 4px;
  transition: 0.26s;
  background-color: #d6dee2;

  span {
    font-size: 14px;
    line-height: 18px;
    color: #60707d;
    transition: 0.26s;
  }

  &.insight {
    background-color: rgb(243, 151, 43);

    span {
      color: #ffffff;
    }
  }

  &.unknown {
    background-color: #45db54;
    box-shadow: 0 4px 8px rgba(69, 219, 84, 0.2);

    span {
      color: #ffffff;
    }
  }
}
</style>
